import { call, put, fork, take, select } from 'redux-saga/effects';
import {
  buyer as buyerActions,
  createStore as createStoreActions,
  addStoreDisabled as addStoreDisabledActions,
} from './actions';
import ActionTypes from './constants';
import { getBuyer, addStore, getAddStoreDisabled, handleCheckStoreIsDuplicate } from 'utils/apollo';
import { StoreStatus } from 'types/schema';
import selectChangeStorePage from './selectors';
import { ContainerState } from './types';
import { message } from 'antd';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import { homeProducts } from 'containers/HomePage/actions';

function* fetchBuyer() {
  yield put(buyerActions.request());
  const response = yield call(getBuyer);

  if (!response.errors) {
    yield put(buyerActions.success(response));
  } else {
    yield put(buyerActions.failure(response.errors));
  }
}

function* fetchAddStoreDisabled() {
  yield put(addStoreDisabledActions.request());
  const response = yield call(getAddStoreDisabled);
  if (!response.errors) {
    yield put(addStoreDisabledActions.success(response.addStoreDisable));
  } else {
    yield put(buyerActions.failure(response.errors));
  }
}

function* createStoreFlow() {
  while (true) {
    const state: ContainerState = yield select(selectChangeStorePage());
    const storesLengh = state.buyer?.stores?.length || 0;

    const { payload } = yield take(ActionTypes.CREATE_STORE);
    yield put(createStoreActions.request());
    const isDuplicateStore = yield call(handleCheckStoreIsDuplicate, {
      input: { name: payload.name, location: payload.location },
    });
    if (!isDuplicateStore.errors) {
      if (isDuplicateStore.isDuplicate) {
        message.error(translations(utilsMessages.duplicateStoreError));
        yield put(createStoreActions.failure({} as any));
        return;
      }
    }
    const response = yield call(addStore, { input: payload });

    if (!response.errors) {
      yield put(
        createStoreActions.success({
          ...payload,
          status: storesLengh === 0 ? StoreStatus.Normal : StoreStatus.Pending,
        }),
      );
    } else {
      yield put(createStoreActions.failure(response.errors));
    }
  }
}

// Individual exports for testing
export default function* changeStorePageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(fetchBuyer);
  yield fork(fetchAddStoreDisabled);
  yield fork(createStoreFlow);
  yield put(
    homeProducts.success({
      ['MIGHT_FAVORITE_PRODUCTS']: {
        data: [],
        error: false,
        loading: false,
        isAdded: false,
      },
    }),
  );
}
