/**
 *
 * ChangeStorePage
 *
 */

import * as React from 'react';

import { Col, Divider, Flex, FormInstance, Row, Typography, message, Form } from 'antd';
import { compose, Dispatch } from 'redux';
import Button from 'components/Button/Button';
import Input from 'components/TrackingComponents/Input';
import { Avatar, InputOverplay } from 'components/Company/Avatar';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import Modal from 'components/Modal/Modal';
import PageHeader from 'components/PageHeader/PageHeader';
import { ContainerState, RootState } from './types';
import StoreForm from 'components/StoreForm';
import StoreList from 'components/StoreList';
import bottomBg from './bottom-bg.png';
import { connect } from 'react-redux';
import { createStoreSubmit } from './actions';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import media from 'utils/mediaStyle';
import messages from './messages';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectChangeStorePage from './selectors';
import { setSelectedStore } from 'containers/MainLayout/actions';
import styled, { css } from 'styled-components';
import translations from 'translations';
import LoadingIndicator from 'components/LoadingIndicator';
import { Store } from 'types/schema';
import CloudArrowUp from './Cloud-Arrow-Up.svg';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import utilsMessages from 'utils/messages';
import { Viewport } from 'components/ViewportProvider';
import LightBuldCircle from 'components/CreateStorePopup/LightbulbCircle.svg';
import { CheckCircleOutlined } from '@ant-design/icons';
interface OwnProps {
  changestorepage: ContainerState;
  mainLayout: any;
}

interface StateProps {}

interface DispatchProps {
  onChangeStore: (store: Store) => void;
  onCreateStore: (store: Store) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  background-image: url(${bottomBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10px 20px;
  margin-bottom: 8pt;
  ${media.md`
    padding: 10pt 20pt;
  `};
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
  color: #343538;
  font-family: 'Maven Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const newModalStyle = css`
  top: 0;
  ${media.sm`
    top: 100px;
  `}
  &.ant-modal .ant-modal-content {
    padding: 16px;
  }

  &.ant-modal .ant-modal-header {
    margin-bottom: 0;
    &:after {
      content: '';
      border-bottom: 1px solid rgb(208, 213, 221);
      display: block;
      margin-inline: -16px;
      margin-top: 16px;
    }
  }

  &.ant-modal .ant-modal-body {
    padding-block: 16px;
  }
`;

const UploadButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 20%;
`;

const UploadHint = styled(Flex)`
  text-align: center;
  font-family: 'Maven Pro';
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  max-width: fit-content;
  margin-inline: auto;
`;

const UploadHintTitle = styled(Typography.Text)`
  color: #475467;
  font-weight: 600;
`;

const UploadHintText = styled(Typography.Text)`
  color: #808289;
  font-weight: 400;
  max-width: 32ch;
`;

const NewUploadButton = styled(Button)`
  display: flex;
  min-width: 90px;
  padding: 8px 12px 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background-color: #fff;
`;

const newAvatarWrapperStyle = css`
  height: 244px;
  padding: 20px;
  border-color: #eaeaeb;
  background: #fcfcfd;
`;

const Note = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background: #fffbeb;
  padding: 10px 8px;
  border-radius: 8px;
`;

const NoteIcon = styled(SVGIcon)`
  & svg path {
    stroke: #f5ce47;
  }
`;

const NoteText = styled.div`
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
`;

const NoteForDisableStore = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: #FFECEA;
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: -5px;
  font-weight: 400
`;

const NewImageUploadButton = ({ onFileDrop }) => (
  <UploadButtonWrapper>
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <UploadHint justify="center" align="center" vertical gap={4}>
          <UploadHintTitle>
            <FormattedMessage {...messages.uploadHintTilte} />
          </UploadHintTitle>
          <UploadHintText>
            <FormattedMessage {...messages.uploadHintText} />
          </UploadHintText>
        </UploadHint>
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <NewUploadButton type="primary" icon={<SVGIcon src={CloudArrowUp} />}>
          <FormattedMessage {...messages.uploadImage} />
        </NewUploadButton>
        <Input
          InputComponent={InputOverplay}
          trackingCategory="InputOverplay"
          trackingAction="Upload Image"
          onChange={onFileDrop}
          type="file"
          name="pic"
          accept="image/*"
        />
      </Col>
    </Row>
  </UploadButtonWrapper>
);
export class ChangeStorePage extends React.PureComponent<Props> {
  // TODO: Should be moved to a separate component. All these fields are only for modal.
  public state = {
    isOpenEditModal: false,
    isAlreadySubmit: false,
    imageUrl: '',
    formRef: React.createRef<FormInstance>(),
    isReadyToSubmit: false,
    loading: true,
  };

  public onAddStore = () => {
    const { buyer } = this.props.changestorepage;
    buyer.addStoreDisabled
      ? message.error(translations(messages.disableAddingStore))
      : this.setState({
          isOpenEditModal: true,
          isAlreadySubmit: false,
        });
  };

  public onCloseEditModal = () => {
    this.setState({
      isOpenEditModal: false,
      isAlreadySubmit: false,
    });
  };

  public onSubmitStore = (store: any) => {
    delete store.provinceId;
    delete store.districtId;
    delete store.addressDetail;
    const address = store.location?.formattedAddress || store.location?.address;
    delete store.location?.formattedAddress;
    const location = {
      ...store.location,
      address: address,
    };
    this.props.onCreateStore({
      ...store,
      location: location,
      imageUrl: this.state.imageUrl,
    });
    this.setState({
      isOpenEditModal: false,
      isAlreadySubmit: true,
    });
  };

  public onSetImageUrl = (url: string) => this.setState({ ...this.state, imageUrl: url });

  public handleFormStatusChecker = (values: any) => {
    this.setState({
      isReadyToSubmit: values.isReadyToSubmit,
    });
  };

  public setLoading = (loading: boolean) => {
    this.setState({
      loading: loading,
    });
  };

  public render() {
    const { buyer } = this.props.changestorepage;
    const { isReadyToSubmit } = this.state;
    return (
      <Viewport.Consumer>
        {({ width }) => (
          <Container>
            <Helmet>
              <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
              <meta name="description" content={`${translations(messages.header)}`} />
            </Helmet>
            <PageHeader size="large" center>
              <FormattedMessage {...messages.header} />
            </PageHeader>
            {
              buyer?.stores?.some(store => store.status === 'BLOCKED') && (
                <NoteForDisableStore>
                  <FormattedMessage {...messages.noteForDisableStore} />
                </NoteForDisableStore>
              )
            }
            {buyer.loading ? (
              <LoadingIndicator />
            ) : (
              <StoreList stores={buyer.stores} onChangeStore={this.props.onChangeStore} onAddStore={this.onAddStore} />
            )}
            <Modal
              size="large"
              headerLabel={translations(messages.headerLabel)}
              isOpen={this.state.isOpenEditModal}
              upperCase={false}
              customStyles={newModalStyle}
              paddingless
            >
              <Row gutter={24}>
                <Col lg={8} xs={24}>
                  <Heading>{translations(messages.storeImage)}</Heading>
                  <Avatar
                    src={this.state.imageUrl}
                    onUploadImageSuccess={this.onSetImageUrl}
                    ImageControlsComponent={NewImageUploadButton}
                    highlight
                    customAvatarStyle={newAvatarWrapperStyle}
                  />
                </Col>
                <Col lg={16} xs={24}>
                  {this.state.isOpenEditModal ? (
                    <StoreForm
                      name="storeForm"
                      store={{
                        name: '',
                        location: {
                          address: '',
                          city: 'Ho Chi Minh',
                          district: 'District 1',
                          geoLat: 0,
                          geoLon: 0,
                          ward: '',
                          noteForAddress: '',
                        },
                        telephone: '',
                      }}
                      onCancel={this.onCloseEditModal}
                      onSubmit={this.onSubmitStore}
                      regions={buyer?.region ? [buyer?.region] : []}
                      onFormStatusChecker={this.handleFormStatusChecker}
                      setLoading={this.setLoading}
                      isAlreadySubmit={this.state.isAlreadySubmit}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Note>
                    <NoteIcon src={LightBuldCircle} renderAsImg={false} />
                    {<NoteText>{translations(utilsMessages.storeNoteText)}</NoteText>}
                  </Note>
                </Col>
              </Row>
              <Divider style={{ marginBlock: 12 }} />
              <Row gutter={12} justify={{ xs: 'center', sm: 'start' }}>
                <Col xs={{ span: 12 }} sm={{ offset: 16, span: 4 }}>
                  <Button
                    form="storeForm"
                    trackingCategory="Edit Store Form | Button"
                    trackingAction="Cancel"
                    block
                    type="secondary"
                    ghost
                    style={{ borderColor: '#D0D5DD' }}
                    onClick={this.onCloseEditModal}
                  >
                    <FormattedMessage {...utilsMessages.cancel} />
                  </Button>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                  <Button
                    form="storeForm"
                    trackingCategory="Edit Store Form | Button"
                    trackingAction="Save"
                    block
                    type="bg-primary"
                    htmlType="submit"
                    disabled={!isReadyToSubmit}
                    loading={this.state.loading}
                    icon={isReadyToSubmit && !this.state.loading ? <CheckCircleOutlined /> : ''}
                    style={{ cursor: isReadyToSubmit ? 'pointer' : 'not-allowed', opacity: isReadyToSubmit ? 1 : 0.5 }}
                  >
                    <FormattedMessage {...utilsMessages.save} />
                  </Button>
                </Col>
              </Row>
            </Modal>
          </Container>
        )}
      </Viewport.Consumer>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  changestorepage: selectChangeStorePage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onChangeStore: (s: Store) => {
      dispatch(setSelectedStore(s));
      dispatch(push('/'));
    },
    onCreateStore: (s: Store) => dispatch(createStoreSubmit(s)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'changeStorePage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'changeStorePage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(ChangeStorePage);
