/**
 *
 * StoreForm
 *
 */

import React, { ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styles/styled-components';
import { Col, Input as AInput, Row, Form, Flex, Modal, message } from 'antd';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import utilsMessages from 'utils/messages';
import Input from 'components/TrackingComponents/Input';
// import LocationFormSection from '../LocationFormSection';
import { trimRecursively } from 'utils/trimRecursively';
import translations from 'translations';
import AddressForMap from 'components/AddressForMap';
import StoreMapWrapper from 'components/CreateStorePopup/components/StoreMapWrapper';
import { FIELD_NAME_FULL_TEXT } from 'components/CreateStorePopup/constants';
import confirmMessage from 'components/CreateStorePopup/messages';
import { DEFAULT_REGION_COORDS, INITIAL_CAMERA } from '../CreateStorePopup/constants';
import useWatchFieldChange from 'components/CreateStorePopup/hooks/useWatchFieldChange';
import Dismiss from 'components/CreateStorePopup/images/Dismiss.svg';
import { GoogleMapsContextProvider } from '../../containers/LandingPage/DistributionLocationPage/components/Map/hooks/GoogleMapScriptContext';
import SVGIcon from '../SVGIcon/SVGIcon';
const { confirm } = Modal;

const AntdInput = styled(AInput)<any>``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

interface OwnProps {
  store: {
    name: string;
    location: {
      address: string;
      city: string;
      district: string;
      ward: string;
      geoLat: number;
      geoLon: number;
      noteForAddress: string;
    };
    telephone: string;
  };
  onCancel: () => void;
  onSubmit: (data: any) => void;
  name: string;
  setLoading: (loading: boolean) => void;
  isAlreadySubmit?: boolean;
  regions?: string[];
  onFormStatusChecker?: (data: any) => void;
}

const StoreForm: ForwardRefRenderFunction<any, OwnProps> = (
  { store, onCancel: _onCancel, onSubmit: _onSubmit, setLoading, onFormStatusChecker, name, regions, isAlreadySubmit },
  ref,
) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<any>(null);
  useImperativeHandle(ref, () => form, [form]);
  const defaultCoords = regions?.length ? DEFAULT_REGION_COORDS[regions[0]] : null;
  const [openExtendedMap, setOpenExtendedMap] = useState(false);
  const [placeId, setPlaceId] = useState<string>('');
  const [coords, setCoords] = useState<google.maps.LatLngLiteral | null>(defaultCoords);
  const [isOutOfRegion, setIsOutOfRegion] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { isReadyToSubmit } = useWatchFieldChange(form, regions);

  useEffect(() => {
    if (onFormStatusChecker) {
      onFormStatusChecker({
        isReadyToSubmit: isReadyToSubmit,
      });
    }
  }, [isReadyToSubmit]);

  useEffect(() => {
    form.setFieldValue('geoLat', coords?.lat);
    form.setFieldValue('geoLon', coords?.lng);
  }, [coords?.lat, coords?.lng]);

  const onSubmit = (values) => {
    form.resetFields();
    setCoords(defaultCoords);
    setPlaceId('');
    _onSubmit(trimRecursively(values));
  };

  const handleShowWarning = () => {
    if (!openExtendedMap) {
      messageApi.open({
        type: 'error',
        content: (
          <FormattedMessage
            {...confirmMessage.warningTitle}
            values={{ region: translations(utilsMessages[regions?.[0] || '']) }}
          />
        ),
        className: 'out-of-region-warning',
        icon: (
          <SVGIcon
            style={{ cursor: 'pointer' }}
            src={Dismiss}
            renderAsImg={false}
            onClick={() => messageApi.destroy()}
          />
        ),
        style: {
          marginInline: 'auto',
        },
      });
    }
  };

  const showPromiseConfirm = () => {
    confirm({
      icon: null,
      title: `${translations(confirmMessage.storeLocationConfirm)}`,
      content: `${translations(confirmMessage.storeLocationConfirmDescription)}`,
      onOk: async () => {
        form.submit();
      },
      style: { textAlign: 'center', color: '#242527', fontSize: 16, fontWeight: 400, lineHeight: 24 },
      onCancel: () => {},
      centered: true,
      okButtonProps: { style: { width: '100%', background: '#43A047' } },
      cancelButtonProps: { style: { width: '100%' } },
      okText: `${translations(confirmMessage.confirm)}`,
      cancelText: `${translations(confirmMessage.notConfirm)}`,
      footer: (_, { OkBtn, CancelBtn }) => (
        <Flex gap={12}>
          <CancelBtn />
          <OkBtn />
        </Flex>
      ),
    });
  };

  const formatPhoneNumber = () => {
    if (form.getFieldError('telephone').length) {
      return;
    }
    const regex = /[()+\-.\s]/g;
    const phoneNumberValue = form.getFieldValue('telephone');
    form.setFieldValue('telephone', phoneNumberValue.replace(regex, ''));
  };
  return (
    <GoogleMapsContextProvider
      apiKey={process.env.GOOGLE_MAP_API || ''}
      language="vi"
      region="VN"
      libraries={['maps', 'geocoding', 'marker', 'places']}
      mapCameraProps={INITIAL_CAMERA}
    >
      {contextHolder}
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        requiredMark={false}
        onFinish={(values) => onSubmit(values)}
        size="large"
        name={name}
      >
        <InfoContainer>
          <Row gutter={8}>
            <Col lg={12} xs={24}>
              <FormattedMessage {...messages.storeName}>
                {(storeName) => (
                  <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: storeName }}>
                    {(fieldRequired) => (
                      <Form.Item
                        name="name"
                        label={storeName}
                        colon={false}
                        rules={[
                          { required: true, message: fieldRequired },
                          { pattern: /[a-zA-Z]/g, message: fieldRequired },
                        ]}
                        initialValue={store.name}
                      >
                        <Input
                          InputComponent={AntdInput}
                          trackingCategory="Edit Store Form | Input"
                          trackingAction="Enter Store name"
                          type="text"
                        />
                      </Form.Item>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
            <Col lg={12} xs={24}>
              <FormattedMessage {...utilsMessages.phone}>
                {(phone) => (
                  <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
                    {(fieldRequired) => (
                      <Form.Item
                        name="telephone"
                        label={phone}
                        colon={false}
                        rules={[
                          { required: true, message: fieldRequired },
                          {
                            pattern: /^(?=(?:[()+\-.\s]*\d){9,12}$)[()+\-.,\s\d]*$/,
                            message: translations(messages.invalidPhoneNumber),
                          },
                        ]}
                        initialValue={store.telephone}
                      >
                        <Input
                          InputComponent={AntdInput}
                          trackingCategory="Edit Store Form | Input"
                          trackingAction="Enter Phone"
                          type="text"
                          onBlur={formatPhoneNumber}
                        />
                      </Form.Item>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          <AddressForMap
            form={form}
            fieldNameFullText={FIELD_NAME_FULL_TEXT}
            fieldName={{
              city: 'provinceId',
              district: 'districtId',
              addressDetail: 'addressDetail',
              ward: 'wardId',
              wardId: 'wardId',
              geoLat: 'geoLat',
              geoLon: 'geoLon',
              geoPlusCode: 'geoPlusCode',
            }}
            regions={regions}
            handleShowWarning={handleShowWarning}
            setOpenExtendedMap={setOpenExtendedMap}
            isOpenExtendedMap={openExtendedMap}
            setCoords={setCoords}
            setPlaceId={setPlaceId}
            placeId={placeId}
            setIsOutOfRegion={setIsOutOfRegion}
            setLoading={setLoading}
            isAlreadySubmit={isAlreadySubmit}
          />
        </InfoContainer>
        {openExtendedMap && coords && (
          <StoreMapWrapper
            form={form}
            fieldNameFullText={{
              addressDetail: FIELD_NAME_FULL_TEXT.addressDetail,
              address: 'addressDetail',
              geoLat: 'geoLat',
              geoLon: 'geoLon',
              geoPlusCode: 'geoPlus',
            }}
            hideMarker={!coords}
            coords={coords}
            setCoords={setCoords}
            placeId={placeId}
            setPlaceId={setPlaceId}
            setIsOutOfRegion={setIsOutOfRegion}
            isOutOfRegion={isOutOfRegion}
            openExtendedMap={openExtendedMap}
            setOpenExtendedMap={setOpenExtendedMap}
            currentRegion={regions?.[0] || ''}
          />
        )}
      </Form>
    </GoogleMapsContextProvider>
  );
};

export default StoreForm;
